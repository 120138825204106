import styled from 'styled-components'

export const InfoContainer = styled.div`
  width: 110px;
  height: 60px;
  margin-left: 16px;
  font-size: 14px;
`
export const Info = styled.div`
  width: 94px;
  border-radius: 5px;
  height: 44px;
  background-color: #FFFFFF;
  box-shadow: 1px 4px 9px rgba(0,0,0,0.3);
  color: #A8A8A8;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
`
export const DropdownHeader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000 !important;
  width: ${({ visible }) => visible ? '100%' : '0'};
  background-color: #FFFFFF;
  height: 56px;
  transition: .3s ease;
  display: flex;
  justify-content: flex-end;
  i {
    display: ${({ visible }) => visible ? 'block' : 'none'};
    padding: ${({ visible }) => visible ? '16px' : '0'};
  }
`
export const Dropdown = styled.div`
  position: fixed;
  z-index: 1000 !important;
  top: 56px;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: ${({ visible }) => visible ? '100%' : '0'};
  transition: .3s ease;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const PrimaryText = styled.span`
  color: #707070;
  line-height: 14px;
  `
export const SecondaryText = styled.span`
  color: #A8A8A8;
  line-height: 14px;
  font-size: 11px;
`