import React, { Component } from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as BrandIcons from '@fortawesome/free-brands-svg-icons'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import Banner from '@components/Banner'
import Logo from '@components/Logo'
import Button from '@components/Button'
import { HeaderContainer, Info, UserActions, Dropdown, DropdownHeader, DropdownItem, WhatsAppButton } from './styles'
import { isLogged } from '../../redux/LoginRedux'
import { Creators } from '../../actions'
import Config from '../../config'
import NoImage from '../../assets/images/noimage.png'
import Capitalize from '../../helpers/Capitalize'
import GAEvents from '../../helpers/GAEvents'

// Google Analytics
import ReactGA from 'react-ga';

// Facebook Pixel
import ReactPixel from 'react-facebook-pixel'

// Hotjar
import { hotjar } from 'react-hotjar'



class MobileHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false,
      logout: false,
      metaTitle: null,
      metaDescription: null
    }
  }

  componentDidMount() {
    const { attemptGetBanners } = this.props

    attemptGetBanners()
  }

  componentWillReceiveProps(newProps) {
    const { location, fetching, store, productFetching, productDetail,isCookiesAllowed } = newProps
    const { state, pathname } = location

    // Copy current state
    const newState = Object.assign({}, this.state)

    if (!fetching && store) {
      const { config } = store
      const { config_meta_title, config_meta_description, config_analytics_id, config_pixel_id, config_hotjar_id } = config

      if(isCookiesAllowed === true){

        if (config_analytics_id && config_analytics_id.length > 0){
          ReactGA.initialize(config_analytics_id)
          ReactGA.pageview(window.location.pathname + window.location.search)
        }
  
        if (config_pixel_id && config_pixel_id.length > 0){
          ReactPixel.init(config_pixel_id)
          ReactPixel.pageView()
        }
  
        if (config_hotjar_id && config_hotjar_id.length > 0){
          hotjar.initialize(config_hotjar_id, 6)
        }
        
      }

      // Update page title
      document.title = config_meta_title

      newState.metaTitle = config_meta_title
      newState.metaDescription = config_meta_description
    }

    // Pages metas handle
    if (pathname === '/sobre' || pathname === '/contato') {
      const { config } = store
      const { config_store_city, config_name } = config

      switch(pathname) {
        case '/sobre':
          newState.metaTitle = `Saiba mais sobre a ${config_name} - ${config_store_city}`
          newState.metaDescription = `Conheça mais sobre a ${config_name}! Farmácia em ${config_store_city} com o melhor atendimento, medicamentos e linha completa de perfumaria!`
          break
        case '/contato':
          newState.metaTitle = `Entre em contato com ${config_name} aqui!`
          newState.metaDescription = `Clique e veja telefone e todos os contatos da ${config_name}! O atendimento mais rápido e prático de farmácia em ${config_store_city}`
          break
        default:
          break
      }
    }

    // Products metas handle
    if (state && state.metas) {
      const { cachedProduct, category, subcategory } = state
      const metaProduct = cachedProduct || productDetail

      if (!productFetching && metaProduct) {
        const { model, name, categories } = metaProduct
        const { config } = store
        const { config_store_city, config_name } = config

        // Update meta title
        newState.metaTitle = `${Capitalize(model)} ${Capitalize(name)} - ${Capitalize(config_store_city)}`

        // Update meta description
        if (categories && categories.length > 0 && categories[0].name.toLowerCase() !== 'outros') {
          let parentCategory = undefined
          parentCategory = categories.reverse()[0]

          newState.metaDescription = `${model} em ${config_store_city} você encontra aqui no site da ${config_name}, além de toda linha completa de ${parentCategory.name}!`
        } else {
          newState.metaDescription = `${model} em ${config_store_city} você encontra aqui no site da ${config_name}. São milhares de produtos e ofertas!`
        }
      }

      // Maybe we need to set category metas :P
      if (!productFetching && !metaProduct) {
        if (category && !subcategory) {
          const { meta_title, meta_description } = category
         
          newState.metaTitle = meta_title
          newState.metaDescription = meta_description
        }

        if (subcategory) {
          const { meta_title, meta_description } = subcategory
         
          newState.metaTitle = meta_title
          newState.metaDescription = meta_description
        }
      }

      // Update page title
      document.title = newState.metaTitle
    }

    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  toggleDropdown = () => {
    this.setState(state => ({
      ...state,
      dropdownOpen: !state.dropdownOpen
    }))
  }

  onDropdownClick = (logout = false) => {
    const { attemptLogout, resetCurrentAddress } = this.props

    // Let's delay just a little bit, to avoid new props handle to overwrite this :)
    setTimeout(() => {
      this.setState({
        dropdownOpen: false,
        logout
      }, () => {
        if (logout) {
          resetCurrentAddress()
          attemptLogout()
        }
      })
    }, 300)
  }

  _renderMenuDropdown = () => {
    const { loggedIn, user } = this.props
    const { dropdownOpen } = this.state

    return (
      <div style={{ position: 'relative' }}>
        <DropdownHeader visible={dropdownOpen}>
          <div onClick={this.toggleDropdown} style={{width: 50, height: 50}}>
            <i className="material-icons">close</i>
          </div>
        </DropdownHeader>
        <Dropdown visible={dropdownOpen} >
          {
            dropdownOpen ? (
              <React.Fragment>
                {
                  loggedIn ? (
                    <React.Fragment>
                      <DropdownItem style={{marginBottom: 20, paddingBottom: 20, fontFamily: 'Gilmer-Bold', fontSize: 18, borderBottom: '1px solid #A8A8A8'}} >
                        <b>Ola, {user.firstname}</b>
                      </DropdownItem>
                      <DropdownItem onClick={() => this.onDropdownClick(true)}>Sair</DropdownItem>
                    </React.Fragment>
                  ) : (
                    <Link to="/registro">
                      <DropdownItem onClick={() => this.onDropdownClick()}>Receba ofertas</DropdownItem>
                    </Link>
                  )
                }

                <React.Fragment>
                  <div style={{width: '80%', borderTop: '1px solid #A8A8A8'}} />
                  <Link to="/produtos">
                    <DropdownItem onClick={() => this.onDropdownClick()}>Produtos</DropdownItem>
                  </Link>
                  <Link to="/sobre">
                    <DropdownItem onClick={() => this.onDropdownClick()}>Sobre nós</DropdownItem>
                  </Link>
                  <Link to="/contato">
                    <DropdownItem onClick={() => this.onDropdownClick()}>Contato</DropdownItem>
                  </Link>
                </React.Fragment>
              </React.Fragment>
            ) : null
          }
        </Dropdown>
      </div>
    )
  }

  render() {
    const { store, banners, location } = this.props
    const { config } = store
    const { config_name, config_address, config_store_city, config_store_number, config_logo, config_navbar_color, config_whatsapp_phone } = config
    const { logout, metaTitle, metaDescription } = this.state

    const storeAddress = `${config_address}, ${config_store_number ? config_store_number + ',' : ''} ${config_store_city || ''}`
    const storeLogo = config_logo && config_logo.length > 0 ? `${Config.s3Bucket}${config_logo}` : NoImage

    let bannerImages = []
    if (banners) {
      bannerImages = banners.map(p => {
        const { image } = p

        return `${Config.s3Bucket}${image}`
      })
    }

    return (
      <HeaderContainer>
        <Helmet>
          {metaTitle ? <meta name="title" content={metaTitle} /> : null}
          {metaDescription ? <meta name="description" content={metaDescription} /> : null}
        </Helmet>

        <Banner images={bannerImages} mobile={true} defaultColor={config_navbar_color} />
        <Link to="/">
          <Logo image={storeLogo} alt={config_name} mobile={true} />
        </Link>

        <UserActions>
          <Button 
            color="secondary"
            onClick={this.toggleDropdown}
            style={{
              display: 'flex',
              background: 'rgba(0, 0, 0, .5)',
              justifyContent: 'center',
              alignItems: 'center',
              height: 40,
              width: 40,
              fontSize: 22,
              borderRadius: 3,
              padding: 0,
              marginLeft: 10,
              borderColor: '#fff',
              color: '#fff'
            }}
            >
            <FontAwesomeIcon icon={Icons.faBars} />
          </Button>
        </UserActions>

        <Info>
          <h1>{config_name}</h1>
          <p style={{cursor: 'pointer'}} onClick={() => window.open(`https://www.google.com/maps/place/${storeAddress}`, '_blank') }>{storeAddress}</p>
        </Info>

        {logout ? <Redirect to="/" /> : null}
        {location && location.pathname === '/' ? <Redirect exact from="/" to="/produtos" /> : null}

        {this._renderMenuDropdown()}

        {
          config_whatsapp_phone && config_whatsapp_phone.length > 0 ? (
            <WhatsAppButton href={`https://api.whatsapp.com/send?phone=55${config_whatsapp_phone.replace(/\D+/g, '')}&text=Olá, estava no site e preciso de auxílio`} target="_blank" onClick={() => { GAEvents({ category: 'Contato', action: 'Click no botão do WhatsApp' }) }}>
              <FontAwesomeIcon icon={BrandIcons.faWhatsapp} size="2x" />
            </WhatsAppButton>
          ) : null
        }
      </HeaderContainer>
    )
  }
}


const mapStateToProps = ({ login, startup, banner, product }) => ({
  fetching: startup.fetching,
  store: startup.store,
  user: login.user,
  banners: banner.banners,
  productFetching: product.fetching,
  productDetail: product.product,
  loggedIn: isLogged(login)
})

const mapPropsToDisptach = dispatch => ({
  attemptLogout: () => dispatch(Creators.logoutRequest()),
  attemptGetBanners: () => dispatch(Creators.bannerListRequest()),
  resetCurrentAddress: () => dispatch(Creators.setCurrentAddressRequest(null))
})

export default withRouter(connect(mapStateToProps, mapPropsToDisptach)(MobileHeader))