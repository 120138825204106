import React, { Component } from 'react'
import { connect } from 'react-redux'
import Body from '@components/MobileBody'
import ContactCard from '@components/ContactCard'
import { Col, Row } from 'react-flexbox-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as BrandIcons from '@fortawesome/free-brands-svg-icons'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { List } from './styles'
import GAEvents from '../../helpers/GAEvents'

class Contact extends Component {
  render() {
    const { store } = this.props
    const { config } = store
    const { 
      config_address,
      config_store_city,
      config_store_number,
      config_phone,
      config_email,
      config_whatsapp_phone
    } = config

    /*
      <li>
        <FontAwesomeIcon icon={BrandIcons.faFacebookF} style={{marginRight: 15}} />
        Facebook
      </li>
      <li>
        <FontAwesomeIcon icon={BrandIcons.faTwitter} style={{marginRight: 8}} />
        Twitter
      </li>
      <li>
        <FontAwesomeIcon icon={BrandIcons.faInstagram} style={{marginRight: 10}} />
        Instagram
      </li>
    */

    return (
      <Body>
        <Row>
          <Col xs={12}>
            <List style={{marginTop: 30}}>
              {
                config_whatsapp_phone ? (
                  <li>
                    <a href={`https://wa.me/55${config_whatsapp_phone.replace(/\D+/g, '')}`} target="_blank" rel="noopener noreferrer" onClick={() => { GAEvents({ category: 'Contato', action: 'Click no botão do WhatsApp' }) }}>
                      <FontAwesomeIcon icon={BrandIcons.faWhatsapp} style={{marginRight: 10}} />
                      WhatsApp
                    </a>
                  </li>
                ) : null
              }
              <li>
                <a href={`tel:${config_phone}`} style={{outline: 'none'}} onClick={() => { GAEvents({ category: 'Contato', action: 'Click no botão de Telefone' }) }}>
                  <FontAwesomeIcon icon={Icons.faPhone} rotation={90} style={{marginRight: 8}} />
                  {config_phone}
                </a>
              </li>
            </List>
          </Col>
        </Row>
        <Row style={{marginTop: 25}}>
          <Col xs={9} xsOffset={1}>
            <ContactCard title={config_store_city}>
              <List>
                <li>{config_address}, {config_store_number}</li>
                <li>{config_phone}</li>
                <li>{config_email}</li>
              </List>
            </ContactCard>
          </Col>
        </Row>
      </Body>
    )
  }
}

const mapStateToProps = ({ startup }) => ({
  store: startup.store
})

export default connect(mapStateToProps, null)(Contact)
