import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Body } from './styles.js'
import DrugstoreInfo from '@components/DrugstoreInfo'
import PulsingCircle from '@components/Icons/PulsingCircle'
import { Grid, Col, Row, } from 'react-flexbox-grid'
import moment from 'moment'
import { Creators } from '../../actions'
import './index.css'

const WEEK_DAY = [
  'Seg',
  'Ter',
  'Qua',
  'Qui',
  'Sex',
  'Sáb',
  'Dom',
  'Fer'
]

class BodyContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingDeliveries: false,
      storeOpen: true,
      deliverySchedule: []
    }
  }

  componentDidMount() {
    const { attemptGetDeliverySchedule } = this.props

    attemptGetDeliverySchedule()
  }

  componentWillReceiveProps(newProps) {
    const { _delivery } = newProps
    let newState = Object.assign({}, this.state)

    // Delivery schedule loaded
    if (!_delivery.fetching && _delivery.schedule !== null) {
      const { schedule } = _delivery
      const now = moment()
      let storeOpen = false

      newState.deliverySchedule = schedule

      schedule.forEach(p => {
        if (Number(p.day) === now.weekday()) {
          const timeStart = moment(p.start, 'HH:mm:ss')
          const timeEnd = moment(p.end, 'HH:mm:ss')
          
          if (now.unix() > timeStart.unix() && now.unix() < timeEnd.unix()) {
            storeOpen = true
          }
        }
      })

      if (storeOpen !== newState.storeOpen) {
        newState.storeOpen= storeOpen
      }
    }

    // Update state if has changed
    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  _renderScheduleDropdownContent = () => {
    const { deliverySchedule } = this.state
    
    return (
      <Grid className="schedule">
        {
          deliverySchedule.map((schedule, index) => {
            const { day, start, end } = schedule

            const startTime = moment(start, 'HH:mm:ss')
            const endTime = moment(end, 'HH:mm:ss')
            const exactDay = Number(day - 1)

            let startStr = `${startTime.format('HH')}h`
            let endStr = `${endTime.format('HH')}h`
            
            if (startTime.minutes() > 0) {
              startStr += startTime.format('mm')
            }
            if (endTime.minutes() > 0) {
              endStr += endTime.format('mm')
            }

            return (
              <Row key={index}>
                <Col xs={3} className="primary-text">
                  <strong>{WEEK_DAY[exactDay]}.</strong>
                </Col>
                <Col xs={8} xsOffset={1} className="secondary-text" style={{fontSize: 14}}> {`${startStr} às ${endStr}`}</Col>
              </Row>
            )
          })
        }
      </Grid>
    )
  }

  _renderBodyHeader = () => {
    const { filterComponent } = this.props
    const { deliverySchedule, storeOpen } = this.state

    let scheduleTime = ''
    let now = moment()
    deliverySchedule.forEach(schedule => {
      const { day, start, end } = schedule
      
      if (Number(day) === now.weekday()) {
        const startTime = moment(start, 'HH:mm:ss')
        const endTime = moment(end, 'HH:mm:ss')

        let startStr = `${startTime.format('HH')}h`
        let endStr = `${endTime.format('HH')}h`

        scheduleTime = `Hoje: ${startStr} às ${endStr}`
      }
    })

    return (
      <Body>
        {
          filterComponent
        }

        <DrugstoreInfo
          icon={<PulsingCircle open={storeOpen} />}
          primaryText="Horários de funcionamento"
          secondaryText={scheduleTime}
          dropdown
        >
          {this._renderScheduleDropdownContent()}
        </DrugstoreInfo>
      </Body>
    )
  }

  render() {
    const { children } = this.props
  
    return (
      <React.Fragment>
        {
          this._renderBodyHeader()
        }

        <Body column>
          {children}
        </Body>
        <ToastContainer />
      </React.Fragment >
    )
  }
}

const mapStateToProps = ({ delivery, payment, address }) => ({
  _delivery: delivery
})

const mapDispatchToProps = dispatch => ({
  attemptGetDeliverySchedule: () => dispatch(Creators.deliveryScheduleRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(BodyContainer)
