import styled from 'styled-components'

export const SelectContainer = styled.div`
  display: flex;
  height: ${({height}) => height ? `${height}px` : '40px'}
  width: ${({width}) => width ? `${width}px` : '100%'}
  border: ${({open}) => `1px solid ${open ? '#00BF91' : '#707070'}`};
  border-bottom: ${({open}) => !open ? '1px solid #707070' : 'none'};
  border-radius: 10px;
  border-bottom-right-radius: ${({open}) => !open ? '10px' : '0px'};
  border-bottom-left-radius: ${({open}) => !open ? '10px' : '0px'};
  color: #707070;
  font-family: Gilmer;
  font-size: ${({fontSize}) => fontSize ? `${fontSize}px` : '16px'};
  justify-content: ${({textAlign}) => textAlign || 'flex-start'};
  align-items: center;
  outline: none;
  background-color: #FFFFFF;
  background-color: ${({disabled}) => disabled ? `#ccc` : '#FFFFFF'};
  cursor: pointer;
  user-select: none;
`
export const Placeholder = styled.span`
  font-family: ${({active}) => active ? 'Gilmer' : 'Gilmer-Light'};
  font-size: ${({fontSize}) => fontSize ? `${fontSize}px` : '16px'};
  color: #707070;
  padding-left: 10px;
`
export const SelectContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({width}) => `${width - 2}px`}
  max-width: ${({width}) => `${width - 2}px`}
  height: 280px;
  margin-top: 160px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 1px solid #00BF91;
  border-right: 1px solid #00BF91;
  border-bottom: 1px solid #00BF91;
  background-color: #FFFFFF;
  overflow-y: auto;
  overflow-x: hidden;
  left: ${({ leftOffset }) => `${leftOffset}px`};
  z-index: 999;
`
