import styled from 'styled-components'

export const PrimaryButton = styled.button`
  border-radius: 8px;
  border: 0;
  background-color: #00BF91;
  height: 40px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Gilmer-Light;
  font-size: 15px;
  outline: none;
  padding: 0px 25px 0px 25px;
  -webkit-transition: background-color .5s;
  transition: background-color .5s;
  &:hover {
    background-color: #00a881;
  }
`

export const OutlineButton = styled.button`
  border-radius: 5px;
  border: 1px solid #707070;
  background-color: transparent;
  color: #707070;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  outline: none;
  padding: 10px 5px 10px 5px;
  -webkit-transition: background-color .5s, color .5s;
  transition: background-color .5s, color .5s;

  &:hover {
    background-color: #707070;
    color: #ffffff;
  }
`
