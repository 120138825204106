import React from 'react'
import { Info, PrimaryText, SecondaryText, DropdownHeader, Dropdown, InfoContainer } from './styles.js'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Arrow from '@components/Icons/Arrow'
import './index.css'

class Container extends React.Component {
  constructor() {
    super()
    this.state = {
      visible: false
    }
  }

  toggleDropwdown = () => {
    this.setState(state => ({ visible: !state.visible }))
  }

  closeDropdown = () => {
    this.setState(state => ({ visible: false }))
  }

  render() {
    const { icon, primaryText, secondaryText, dropdown = false, dropdownContent, children } = this.props
    const { visible } = this.state
    return (
      <InfoContainer>
        <Info onClick={this.toggleDropwdown} dropdown={dropdown}>
          <Grid>
            <Row middle="xs">
              <Col xs={12}>
                <Row>
                  <Col xs={dropdown ? 9 : 12} style={{maxHeight: 18}}>
                    {icon}
                  </Col>
                  {dropdown &&
                    <Col xs={3}>
                      <Arrow width={10} height={5} style={{ transform: visible ? 'rotate(180deg)' : 'rotate(0)' }} />
                    </Col>
                  }
                </Row>
              </Col>
            </Row>
            <Row middle="xs">
              <Col xs={12}>
                <Row>
                  <PrimaryText>{primaryText}</PrimaryText>
                </Row>
                <Row>
                  <SecondaryText>{secondaryText}</SecondaryText>
                </Row>
              </Col>
            </Row>
          </Grid>
        </Info>
        <div style={{ position: 'relative' }}>
          {dropdown && (
            <React.Fragment>
              <DropdownHeader visible={visible}>
                <i className="material-icons" onClick={this.closeDropdown}>close</i>
              </DropdownHeader>
              <Dropdown visible={visible} >
                {visible ? (dropdownContent || children) : null}
              </Dropdown>
            </React.Fragment>
          )}
        </div>
      </InfoContainer>
    )
  }
}

export default Container