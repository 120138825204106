import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Search from '@components/Icons/Search'
import {
  Container,
  InputContainer,
  BtnContainer,
  Input,
  Button
} from './styles'
import _ from 'lodash'

export default class SearchBar extends Component {
  static propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func
  }

  static defaultProps = {
    value: '',
    placeholder: '',
    name: 'input-search',
    onChange: () => {}
  }

  constructor(props) {
    super(props)

    this.onChange = _.debounce(props.onChange, 500)

    this.state = {
      searchValue: props.value
    }
  }

  componentDidUpdate(prevProps) {
    // Hmmm, seems that our value has been empty, we got it :D
    if (prevProps.value.length > 0 && this.props.value.length === 0) {
      this.setState({
        searchValue: ''
      })
    }
  }

  onSearchChange = ({ target: { value } }) => {
    this.setState({
      searchValue: value
    }, () => {
      this.onChange(value)
    })
  }

  render() {
    const { placeholder, name } = this.props
    const { searchValue } = this.state

    return (
      <Container>
        <InputContainer>
          <Input value={searchValue} placeholder={placeholder} name={name} onChange={this.onSearchChange} />
        </InputContainer>
        <BtnContainer>
          <Button>
            <Search />
          </Button>
        </BtnContainer>
      </Container>
    )
  }
}