import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Nav, NavBarItem, NavBarItemDivider, Indicator } from './styles.js'
import List from '@components/Icons/List'
import About from '@components/Icons/About'
import Contact from '@components/Icons/Contact'
import './index.css'

class NavBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      productsHover: false,
      aboutHover: false,
      contactHover: false
    }
  }

  onUpdateItemHover = (item, isHovered) => {
    this.setState({
      [item]: isHovered
    })
  }

  _indicatorClasses = (name, currentPath) => {
    const { location } = this.props
    const { pathname } = location
    const item = this.state[name]

    if (item === undefined) return ''
    else {
      let _class = ''

      if (item === true || pathname === currentPath) _class = 'hover'
      if (pathname === currentPath) _class = _class.length === 0 ? 'hover active' : _class + ' active'

      return _class
    }
  }

  render() {
    const { store, mobile, location } = this.props
    const { config } = store
    const { config_navbar_color } = config
    const { pathname } = location

    return (
      <div>
        <Nav bgColor={config_navbar_color}>
          <NavBarItem onMouseEnter={() => this.onUpdateItemHover('productsHover', true)} onMouseLeave={() => this.onUpdateItemHover('productsHover', false)}>
            <Link to="/produtos" className={`link ${pathname === '/produtos' ? "active" : ""}`}>
              <List width={mobile ? 19 : 20} height={mobile ? 19 : 20} color={pathname === '/produtos' ? "#fff" : "#ccc"} opacity={pathname === '/produtos' ? 1 : 0.80} style={{marginTop: -2}} />
              Produtos
            </Link>
            <Indicator className={this._indicatorClasses('productsHover', '/produtos')}/>
          </NavBarItem>

          <NavBarItemDivider />

          <NavBarItem onMouseEnter={() => this.onUpdateItemHover('aboutHover', true)} onMouseLeave={() => this.onUpdateItemHover('aboutHover', false)}>
            <Link to="/sobre" className={`link ${pathname === '/sobre' ? "active" : ""}`}>
              <About width={mobile ? 12 : 16} height={mobile ? 12 : 16} color={pathname === '/sobre' ? "#fff" : "#ccc"} opacity={pathname === '/sobre' ? 1 : 0.80} />
              Sobre nós
            </Link>
            <Indicator className={this._indicatorClasses('aboutHover', '/sobre')} style={{marginTop: 3}} />
          </NavBarItem>

          <NavBarItemDivider />
          
          <NavBarItem onMouseEnter={() => this.onUpdateItemHover('contactHover', true)} onMouseLeave={() => this.onUpdateItemHover('contactHover', false)}>
            <Link to="/contato" className={`link ${pathname === '/contato' ? "active" : ""}`}>
              <Contact width={mobile ? 16 : 20} height={mobile ? 16 : 20} color={pathname === '/contato' ? "#fff" : "#ccc"} opacity={pathname === '/contato' ? 1 : 0.80} />
              Contato
            </Link>
            <Indicator className={this._indicatorClasses('contactHover', '/contato')}/>
          </NavBarItem>
        </Nav>
      </div>
    )
  }
}

const mapStateToProps = ({ startup, router }) => ({
  store: startup.store,
  _router: router
})

export default withRouter(connect(mapStateToProps, null)(NavBar))