import styled from 'styled-components'

export const Input = styled.input`
  height: ${({height}) => height ? `${height}px` : '26px'}
  width: ${({width, textAlign}) => width ? `${width}px` : textAlign === 'center' ? '100%' : 'calc(100% - 10px)'};
  border-radius: 10px;
  border: 1px solid #707070; 
  color: #707070;
  font-family: Gilmer;
  font-size: ${({fontSize}) => fontSize ? `${fontSize}px` : '22px'};
  text-align: ${({textAlign}) => textAlign || 'left'};
  outline: none;
  background-color: #FFFFFF;
  padding: ${({textAlign}) => textAlign === 'center' ? '15px 0 15px 0' : '15px 0 15px 10px'};
  
  &:focus {
    border: 1px solid #00BF91;
  }

  &::placeholder {
    font-family: Gilmer-Light;
  }
`
