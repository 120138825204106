import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { AllHtmlEntities } from 'html-entities'
import { Row, Col } from 'react-flexbox-grid'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as BrandIcons from '@fortawesome/free-brands-svg-icons'
import * as Icons from "@fortawesome/free-solid-svg-icons"
import { Image, ProductInfoContainer, Title, Text, Description, ControlButton, ControlDescription, SpecialBadge, PriceCompare, WAButton, TelButton } from './styles'
import { floatToBRL } from '../../helpers/MoneyFormat'
import Capitalize from '../../helpers/Capitalize'
import Config from '../../config'
import NoImage from '../../assets/images/noimage.png'
import NoImageGeneric from '../../assets/images/noimage-generic.png'
import GAEvents from '../../helpers/GAEvents'

const htmlEntities = new AllHtmlEntities()

const AB_DESCRIPTION = `
  VENDA PROIBIDA VIA INTERNET.
  MEDICAMENTO SUJEITO A CONTROLE ESPECIAL MEDIANTE A RETENÇÃO DE RECEITUÁRIO SIMPLES DE DUAS VIAS. VÁLIDA POR 10 DIAS A CONTAR DA DATA DE SUA DATA DE EMISSÃO. RDC Nº 20/2011.
`
const GENERIC_DESCRIPTION = `
  VENDA PROIBIDA VIA INTERNET.
  MEDICAMENTO SUJEITO A CONTROLE ESPECIAL MEDIANTE RETENÇÃO DA RECEITA. PORTARIA Nº 344 - 01/02/1999 - MINISTÉRIO DA SAÚDE.
`

export default class ProductInfo extends Component {
  static propTypes = {
    ean: PropTypes.number,
    ms: PropTypes.number,
    name: PropTypes.string,
    model: PropTypes.string,
    presentation: PropTypes.string,
    description: PropTypes.string,
    laboratory: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number,
    special: PropTypes.object,
    control: PropTypes.shape({
      description: PropTypes.string,
      initials: PropTypes.string
    }),
    classification: PropTypes.string,
    hidePrice: PropTypes.bool,
    storeWANumber: PropTypes.string,
    storePhoneNumber: PropTypes.string
  }

  static defaultProps = {
    hidePrice: false
  }

  mountSchemaMarkup = () => {
    const { ean, name, model, image, price, quantity, classification } = this.props
    const { s3Bucket } = Config
    const productImage = image && image.split('/')[0] !== 'catalog' ? `${s3Bucket}${image}` : (classification === 'GENERICO' ? NoImageGeneric : NoImage)
    let availability = 'http://schema.org/InStock'

    if (quantity > 0 && quantity <= 5) {
      availability = 'http://schema.org/LimitedAvailability'
    }
    if (quantity <= 0) {
      availability = 'http://schema.org/OutOfStock'
    }

    const markup = {
      '@context': 'http://schema.org/',
      '@type': 'Product',
      name: `${Capitalize(model)} ${Capitalize(name)}`,
      image: productImage,
      gtin: ean.toString(),
      sku: ean.toString(),
      offers: {
        '@type': 'Offer',
        priceCurrency: 'BRL',
        price: price.toFixed(2),
        availability
      }
    }

    return JSON.stringify(markup)
  }

  render() {
    const { ean, ms, name, model, presentation, description, laboratory, image, price, quantity, special, control, classification, hidePrice, storeWANumber, storePhoneNumber } = this.props
    const { s3Bucket } = Config

    const productImage = image && image.split('/')[0] !== 'catalog' ? `${s3Bucket}${image}` : (classification === 'GENERICO' ? NoImageGeneric : NoImage)
    let htmlDescription = description
    const htmlParsed = htmlEntities.decode(htmlDescription)
    if (htmlParsed.length > 0) {
      htmlDescription = htmlParsed
    }

    const waMessage = `Olá, estava vendo o *${model} ${name}*${!hidePrice ? `, de valor *${special ? floatToBRL(special.price) : floatToBRL(price)}*` : ''} no site.`
    const waLink = `https://wa.me/55${storeWANumber.replace(/\D+/g, '')}?text=${encodeURI(waMessage)}`
    
    const telLink = `tel:${storePhoneNumber.replace(/\D+/g, '')}`

    return (
      <React.Fragment>
        <Helmet>
          <script type="application/ld+json">
            {
              this.mountSchemaMarkup()
            }
          </script>
        </Helmet>

        <Row style={{borderBottom: '1px solid #A8A8A8'}}>
          <Col xs={5} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRight: '1px solid #A8A8A8'}}>
            {
              !hidePrice && special ? (
                <SpecialBadge>
                  <span>-{special.discount.toFixed(0)}</span>%
                </SpecialBadge>
              ) : null
            }
            <Image src={productImage} alt={`${Capitalize(model)} ${Capitalize(name)}`} />
          </Col> 

          <Col xs={7}>
            <ProductInfoContainer>
              <Title bold>{Capitalize(model)}</Title>
              <Text style={{ marginBottom: 15 }}>{Capitalize(name)}</Text>

              {presentation ? <Text>Apresentação: {presentation}</Text> : null}
              {laboratory.length > 0 ? <Text>Fabricante: {Capitalize(laboratory)}</Text> : null}
              <Text>EAN: {ean}</Text>
              {ms > 0 ? <Text>Código Ministério da Saúde: {ms}</Text> : null}
              {control ? <Text>Controle: {control.description}</Text> : null}

              {
                !hidePrice ? (
                  <div style={{marginTop: '15%'}}>
                    {
                      // We do not have stock? O.O -> :(
                      quantity <= 0 ? (
                        <span style={{fontSize: 12, color: '#ff4646'}}>
                          <FontAwesomeIcon icon={Icons.faTimesCircle} color="#ff4646" style={{marginRight: 5}} />
                          Sem estoque
                        </span>
                      ) : null
                    }
                    {
                      // Our last products, JUST BUY IT!
                      quantity > 0 && quantity <= 5 ? (
                        <span style={{fontSize: 12, color: '#ffa346'}}>
                          <FontAwesomeIcon icon={Icons.faExclamationCircle} color="#ffa346" style={{marginRight: 5}} />
                          Restam só {quantity} {`unidade${quantity > 1 ? 's' : ''}`}
                        </span>
                      ) : null
                    }
                    {
                      // We are good \o/
                      quantity > 5 ? (
                        <span style={{fontSize: 12, color: '#00bf91'}}>
                          <FontAwesomeIcon icon={Icons.faCheckCircle} color="#00bf91" style={{marginRight: 5}} />
                          Em estoque
                        </span>
                      ) : null
                    }
                  </div>
                ) : null
              }

              {
                !hidePrice ? (
                  special ? (
                    <PriceCompare>
                      <Text style={{color: '#A8A8A8', textDecoration: 'line-through', fontSize: 18}}>{floatToBRL(price)}</Text>
                      <Text bold style={{marginTop: 5, color: '#00BF91', fontSize: 22}}>{floatToBRL(special.price)}</Text>
                    </PriceCompare>
                  ) : (
                    <Text bold style={{marginTop: '20%', color: '#00BF91', fontSize: 22}}>{floatToBRL(price)}</Text>
                  )
                ) : (
                  <Text bold fontSize={16} style={{marginTop: '15%'}}>Fale conosco para consultar preço e disponibilidade</Text>
                )
              }
              
              {
                control === null ? (
                  <React.Fragment>
                    <WAButton href={waLink} target="_blank" style={{marginTop: 15, marginBottom: 15, width: '60%'}} onClick={() => { GAEvents({ category: 'Contato', action: 'Click no botão do WhatsApp' }) }}  >
                      <FontAwesomeIcon icon={BrandIcons.faWhatsapp} size="2x" />
                      <span style={{fontFamily: 'Gilmer-Bold', fontSize: 15, alignSelf: 'center', marginLeft: 10}}>Chamar no WhatsApp</span>
                    </WAButton>

                    <TelButton href={telLink} target="_blank" style={{marginTop: 5, marginBottom: 15, width: '57%'}} onClick={() => { GAEvents({ category: 'Contato', action: 'Click no botão de Telefone' }) }} >
                      <FontAwesomeIcon icon={Icons.faPhone} rotation={90} size="1x" />
                      <span style={{fontFamily: 'Gilmer-Bold', fontSize: 15, alignSelf: 'center', marginLeft: 10}}>{storePhoneNumber}</span>
                    </TelButton>
                  </React.Fragment>
                ) : (
                  <ControlButton>
                    VENDA EXCLUSIVA NAS LOJAS FÍSICAS
                  </ControlButton>
                )
              }
            </ProductInfoContainer>
          </Col>
        </Row>

        <Row>
          <Col xs={12} style={{padding: 20}}>
            {
              control ? (
                <ControlDescription>
                  {
                    control.initials.toLowerCase() === 'ab' ? AB_DESCRIPTION : GENERIC_DESCRIPTION
                  }
                </ControlDescription>
              ) : null
            }

            <Description dangerouslySetInnerHTML={{__html: htmlDescription}} />
            <div id="standoutDivAutomatico" />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
