import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Modal from 'react-responsive-modal'
import { isLogged } from '../../redux/LoginRedux'
import { Creators } from '../../actions'
import { AppContainer } from './styles'
import DomainExtract from '../../helpers/DomainExtract'

import Header from '@components/Header'
import MobileHeader from '@components/MobileHeader'
import Footer from '@components/Footer'
import MobileFooter from '@components/MobileFooter'
import StoreURLsModal from '@components/StoreURLsModal'
import CookiePopup from '@components/CookiePopup'

// Default view
import Splash from '@views/Splash'
import Page404 from '@views/404'

// Routes
import NavRoutes from '../../navigation/NavRoutes'
import TagManager from 'react-gtm-module'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      showApp: false,
      modalOpen: false,
      metaTitle: null,
      metaDescription: null,
      isCookiesAllowed :false
    }

    props.startup()
  }

  componentDidMount() {
    const { refreshToken, loggedIn } = this.props

    const isCookiesAllowed = localStorage.getItem('isCookiesAllowed')

    // Refresh user token
    if(isCookiesAllowed === 'true'){
      this.setState({
        ...this.state,
        isCookiesAllowed : true
      },()=>{
        if (loggedIn) {
          setTimeout(()=>{
            refreshToken()
          },1500)
        }
      })
    }
  }

  componentWillReceiveProps(newProps) {
    const { fetching, store } = newProps
    const { loaded } = this.state
    const isCookiesAllowed = localStorage.getItem('isCookiesAllowed')

    // Copy current state
    const newState = Object.assign({}, this.state)

    if(isCookiesAllowed === 'true'){
      newState.isCookiesAllowed = true
    }

    // Our store is fully configured?
    if (!loaded && !fetching && store !== null) {
      const { config,  urls } = store
      const { config_google_tag_manager_id } = config

      if (!newState.tagManagerInitialized && config_google_tag_manager_id && isCookiesAllowed === 'true' ) {
        newState.tagManagerInitialized = true

        TagManager.initialize({
          gtmId: config_google_tag_manager_id,
        })
        
      }

      // Update initial states
      newState.loaded = true
      newState.modalOpen = urls && urls.length > 0
    }

    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  closeStoreModal = () => {
    const { setStoreRedirect } = this.props

    this.setState({
      modalOpen: false
    }, () => {
      setStoreRedirect(false)
    })
  }

  allowCookies = ()=>{
    this.setState({
      ...this.state,
      isCookiesAllowed : true
    },()=>{
      localStorage.setItem('isCookiesAllowed',true)
    })
  }

  render() {
    const { storeRedirect } = this.props
    const { loaded, showApp, modalOpen, isCookiesAllowed } = this.state
    const isMobile  = window.innerWidth < 768

    let schemaMarkup = undefined
    let tawkEmbed = undefined
    let storeUrls = []

    if (loaded) {
      const { store } = this.props
      const { config, urls } = store
      const { config_schema_markup, config_tawk_embed } = config

      storeUrls = urls

      // Is valid schema?
      if (config_schema_markup) {
        try {
          schemaMarkup = JSON.parse(config_schema_markup.replace(/&quot;/g,'"'))
        } catch (error) {
          console.log(error)
        }
      }

      // Is valid tawk.to embed?
      if (config_tawk_embed) {
        if (DomainExtract(config_tawk_embed) === 'embed.tawk.to') {
          tawkEmbed = config_tawk_embed
        }
      }
    }

    return (
      <AppContainer style={{overflow: showApp ? 'unset' : 'hidden'}}>
        {
          loaded ? (
            <React.Fragment>
              <Helmet>
                {schemaMarkup ? <script type='application/ld+json'>{`${JSON.stringify(schemaMarkup)}`}</script> : null}
                {tawkEmbed ? <script src={tawkEmbed} /> : null}
              </Helmet>

              <Router>
                <React.Fragment>
                {
                  isMobile ? 
                    <MobileHeader  isCookiesAllowed={isCookiesAllowed} /> : 
                    <Header isCookiesAllowed={isCookiesAllowed} />
                  }

                  {
                    // eslint-disable-next-line
                    !isCookiesAllowed && showApp && (
                      <CookiePopup allowCookies={this.allowCookies} />
                    )
                  }
                  <Switch>
                    {NavRoutes.map((route, index) => {
                      const { path, exact, component, mobileComponent, render } = route

                      return <Route path={path} exact={exact} key={index} component={isMobile && mobileComponent ? mobileComponent : component} render={render} />
                    })}
                    <Route component={Page404} />
                  </Switch>
                </React.Fragment>
              </Router>
              {isMobile ? <MobileFooter /> : <Footer />}
            </React.Fragment>
          ) : null
        }

        {
          (storeUrls && storeUrls.length > 0) && storeRedirect ? (
            <Modal open={modalOpen} showCloseIcon={false} onClose={this.closeStoreModal} styles={{modal: {borderRadius: 5}}} center>
              <StoreURLsModal urls={storeUrls} mobile={isMobile} />
            </Modal>
          ) : null
        }
        <Splash hide={loaded} onFinish={() => this.setState({ showApp: true })} />
      </AppContainer>
    )
  }
}

const mapStateToProps = ({ startup, login }) => ({
  fetching: startup.fetching,
  store: startup.store,
  loggedIn: isLogged(login),
  storeRedirect: login.storeRedirect
})

const mapDispatchToProps = dispatch => ({
  startup: () => dispatch(Creators.startupRequest()),
  refreshToken: () => dispatch(Creators.refreshTokenRequest()),
  setStoreRedirect: (status) => dispatch(Creators.setStoreRedirect(status))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
