import React from 'react'
import { SVG } from './styles'

const Facebook = ({ height = 31, width = 14 }) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 14.774 31.628"
  >
    <g transform="translate(-388 -658)"><path fill="#fff" d="M571.156,565.768c-.006.1-.016.2-.016.3,0,1.625,0,3.249,0,4.874,0,.226-.066.283-.285.28-.883-.009-1.768-.008-2.651.009a5.445,5.445,0,0,0-.9.108,1.2,1.2,0,0,0-.987,1.185c-.043,1.143-.038,2.288-.058,3.433,0,.208.136.173.257.173h4.645l-.574,5.445H566.27v.341q0,7.588-.006,15.177c0,.1-.005.2-.008.3h-5.911c0-.471.006-.943.006-1.414q0-7,0-13.995v-.4h-3.94c-.006-.111-.015-.192-.015-.274,0-1.634,0-3.267-.006-4.9,0-.226.067-.283.286-.282,1.128.01,2.257,0,3.385.009.241,0,.292-.077.293-.3.006-1.465,0-2.93.052-4.394a7.2,7.2,0,0,1,.755-3.064,4.142,4.142,0,0,1,2.5-2.132c.621-.2,1.277-.282,1.918-.417.089-.019.179-.04.268-.06Z" transform="translate(-168.39 92.232)" /></g>
  </SVG>
)

export default Facebook
