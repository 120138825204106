import React from 'react'
import { SVG } from './styles.js'

const Arrow = ({ height = 20, width = 20, ...props }) => (
  <SVG
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 31.998 17.499">
    <path 
      d="M490.426,2851.212l14.5,14.5c-.287-.287,14.5-14.5,14.5-14.5" 
      transform="translate(-488.925 -2849.712)"
      fill="none"
      stroke="#a8a8a8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
  </SVG>
)

export default Arrow
