import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Arrow from '@components/Icons/Arrow'
import { SelectContainer, Placeholder, SelectContent } from './styles'
import './index.css'

export default class PrettySelect extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    onChange: () => {},
    name: '',
    disabled: false
  }

  constructor(props) {
    super(props)

    this.container = undefined

    this.state = {
      open: false,
      selectedItem: undefined
    }
  }

  toggle = () => {
    const { open } = this.state
    const { disabled } = this.props

    if (disabled) return

    this.setState({
      open: !open
    })
  }

  _renderItems = () => {
    const { open } = this.state
    const { children, contentOffset, onChange, name } = this.props

    if (open) {
      const selectItem = ({target}, props) => {
        const { value, children } = props

        target.name = name
        target.value = value ? value.toString() : ''
        target.text = children

        this.setState({
          open: false,
          selectedItem: target
        })

        onChange({target})
      }

      return (
        <SelectContent
          className="pretty-select"
          width={this.container.offsetWidth}
          leftOffset={this.container.offsetLeft + (contentOffset || 0)}
        >
          {
            React.Children.map(children, child => {
              const { props } = child

              return <div {...props} onClick={(ev) => selectItem(ev, props)} />
              //React.cloneElement(child, { onClick: selectItem })
            })
          }
        </SelectContent>
      )
    }
  }

  render() {
    const { open, selectedItem } = this.state
    const { 
      placeholder,
      fontSize,
      textAlign,
      iconAlign,
      disabled
    } = this.props

    const style = {
      marginLeft: iconAlign ? 'auto' : 10,
      justifySelf: iconAlign || 'unset' 
    }

    return (
      <SelectContainer 
        fontSize={fontSize}
        textAlign={textAlign}
        onClick={this.toggle}
        open={open}
        disabled={disabled}
        ref={(refs) => this.container = refs}
      >
        <Placeholder fontSize={fontSize} active={selectedItem !== undefined}>
          {selectedItem !== undefined ? selectedItem.text : placeholder}
        </Placeholder>
        <Arrow className={open ? 'pretty-select-open' : 'pretty-select-close'} style={style} />

        {this._renderItems()}
      </SelectContainer>
    )
  }
}
