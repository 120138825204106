import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loadedStore } from '../redux/StartupRedux'

const storeId = ({ startup }) => loadedStore(startup)

export function * getAboutUs(api) {
  const store_id = yield select(storeId)
  api.setHeaders({'store-id': store_id})
  
  const response = yield call(api.getAboutUs)
  const { ok, data } = response

  if (ok) {
    const { content } = data

    yield put(Creators.aboutUsSuccess(content))
  } else {
    const { error } = data

    yield put(Creators.aboutUsFailure(error))
  }
}
