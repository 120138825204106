import styled from 'styled-components'

export const InfoContainer = styled.div`
  position: relative;
  width: calc(25% - 14px);
  margin-top: 16px;
  margin-left: 16px;
  user-select: none;
  &:last-child {
    margin-right: 0;
  }
`

export const Info = styled.div`
  width: calc(100% - 14px);
  border-radius: 15px;
  height: 80px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 4px rgba(0,0,0,0.1);
  color: #A8A8A8;
  padding: 10px;
  display: flex;
  align-items: center;
  outline: none;
  ${({ dropdown }) => dropdown ? 'cursor: pointer;' : '' }
  @media(min-width: 768px) {
    &:last-child {
      margin-right: 0;
    }
  }
`
export const Dropdown = styled.div`
  width: calc(100% - 12px);
  top: 90px;
  border-radius: 0 0 15px 15px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 4px rgba(0,0,0,0.1);
  color: #A8A8A8;
  padding: 9px;
  position: absolute;
  z-index: 1000 !important;
  display: ${({ visible }) => visible ? 'flex' : 'none' };
  align-items: center;

`
export const PrimaryText = styled.span`
  color: #707070;
  font-size: 14px;
`
export const SecondaryText = styled.span`
  color: #A8A8A8;
  font-size: 12px;
`