import styled from 'styled-components'

export const Body = styled.div`
  position: relative;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 16px;
  display: flex;
  @media(min-width: 768px) {
    width: 85%;
  }
  @media(min-width: 1000px) {
    width: 62%;
    flex-direction: ${({ column }) => column ? 'column' : 'row'};
  }
`
export const ProductCard = styled.div`
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 2px 4px 10px rgba(0,0,0, 0.2);
  height: 214px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
`

export const Img = styled.img`
  height: 150px;
  max-width: 100%;
`

export const ProductInfo = styled.div`
  width: calc(50% - 16px);
  display: flex;
  align-items: center;
  padding: 4px 8px;
  justify-content: ${({ end }) => end ? 'flex-end' : 'flex-start' };
  color: #00bf91;
  font-size: 18px;
  font-weight: bold;
`

export const BRow = styled.div`
  display: flex;
  width: 100%;
  margin: ${({ marged }) => marged ? '16px 0' : '0' };
`
export const BCol = styled.div`
  width: ${({ width }) => width};
  margin-right: ${({ margedRight }) => margedRight ? '16px' : '0' };
`