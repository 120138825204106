import styled from 'styled-components'

export const LogoContainer = styled.img`
  height: 134px;
  width: 134px;
  border-radius: 50%;
  background-color: #F8F8F8;
  position: absolute;
  z-index: 1;
  box-shadow: 2px 4px 10px rgba(0,0,0, 0.2);
  border: 3px solid #CCCCCC;

  ${({ mobile }) => mobile ? `
    top: 40%;
    left: 5%;
    height: 100px;
    width: 100px;
  ` : `
    @media(min-width: 768px) {
      left: 26px;
      top: 50px;
      height: 215px;
      width: 215px;
    }
    @media(min-width: 1000px) {
      height: 234px;
      width: 234px;
      top: 30px;
      left: 19%;
    }
  `}
`
