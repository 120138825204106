import styled from 'styled-components'

export const Title = styled.h2`
  font-family: ${({ bold }) => `Gilmer-${bold ? 'Bold' : 'Light'}`};
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '15px'};
  text-align: center;
  color: #707070;
  line-height: 20px;
  margin: 20px 0 5px 0;
`
