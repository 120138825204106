import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SlickSlider from "react-slick"
import { BannerContainer, BannerImage } from './styles.js'
import './index.css'

const SLIDER_SETTINGS = {
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 10000,
  arrows: true,
  fade: true
}

class Banner extends Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.string),
    defaultColor: PropTypes.string,
    mobile: PropTypes.bool
  }

  static defaultProps = {
    mobile: false,
    defaultColor: '#4E00C9'
  }

  // Make sure our slick has initialized
  init = () => {
    setTimeout(() => {
      if (this.slider !== null) {
        this.slider.slickNext()
      }
    }, 1000)

    setTimeout(() => {
      if (this.slider !== null) {
        this.slider.slickNext()
      }
    }, 5000)
  }

  _renderImages = () => {
    const { images, mobile } = this.props

    if (images) {
      return images.map((img, index) => {
        return (
          <div key={index}>
            <BannerImage src={img} alt="" mobile={mobile} />
          </div>
        )
      })
    }
  }

  render() {
    const { defaultColor } = this.props

    return (
      <BannerContainer color={defaultColor}>
        <SlickSlider {...SLIDER_SETTINGS} ref={slider => this.slider = slider} onInit={this.init}>
          {this._renderImages()}
        </SlickSlider>
      </BannerContainer>
    )
  }
}

export default Banner
