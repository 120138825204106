import styled from 'styled-components'

export const HeaderContainer = styled.div`
  position: relative;
  width: 100vw;
  top: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Info = styled.div`
  background-color: #FFFFFF;
  color: #707070;
  text-align: center;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  h1, p {
    margin: 0;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 9px;
  }
  h1 {
    font-size: 20px;
  }
`
export const UserActions = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`
export const DropdownHeader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  width: ${({ visible }) => visible ? '100%' : '0'};
  background-color: #FFFFFF;
  height: 56px;
  transition: .3s ease;
  display: flex;
  justify-content: flex-end;
  i {
    display: ${({ visible }) => visible ? 'block' : 'none'};
    padding: ${({ visible }) => visible ? '16px' : '0'};
  }
`
export const Dropdown = styled.div`
  position: fixed;
  z-index: 1000;
  top: 56px;
  bottom: 0;
  right: 0;
  width: ${({ visible }) => visible ? '100%' : '0'};
  transition: .3s ease;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
export const DropdownItem = styled.p`
  font-family: Gilmer;
  font-size: 16px;
  color: #A8A8A8;
  line-height: 5px;
  cursor: pointer;

  &:hover {
    color: #7f7f7f;
  }
`
export const WhatsAppButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #25D366;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  right: 20px;
  bottom: 80px;
  color: #FFFFFF;
  z-index: 990;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
`
