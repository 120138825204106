import React from 'react'

// Pages
import Home from '@views/Home'
import Category from '@views/Category'
import Register from '@views/Register'
import Contact from '@views/Contact'
import Aboutus from '@views/Aboutus'

// Mobile pages
import MobileHome from '@views/MobileHome'
import MobileCategory from '@views/MobileCategory'
import MobileAboutUs from '@views/MobileAboutUs'
import MobileContact from '@views/MobileContact'

const routes = [
  { path: '/produtos/:productUrl?', exact: true, name: 'MyPharma', component: Home, mobileComponent: MobileHome },

  { path: '/departamentos/:categorySlug?/:subcategorySlug?', exact: true, name: 'MyPharma', render: (props) => {
    const { params } = props.match
    const { categorySlug } = params
    const isMobile  = window.innerWidth < 768

    if (categorySlug) {
      return isMobile ? <MobileHome {...props} /> : <Home {...props} />
    }

    return isMobile ? <MobileCategory {...props} /> : <Category {...props} />
  } },

  { path: '/registro', exact: true, name: 'Registro', component: Register },
  { path: '/contato', exact: true, name: 'Contato', component: Contact, mobileComponent: MobileContact },
  { path: '/sobre', exact: true, name: 'Sobre nós', component: Aboutus, mobileComponent: MobileAboutUs }
]

export default routes
