import styled from 'styled-components'

export const Image = styled.img`
  max-width: 80%;
  height: auto;
  padding-bottom: 50px;
`
export const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`
export const Title = styled.h1`
  font-family: ${({ bold }) => `Gilmer-${bold ? 'Bold' : 'Light'}`};
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '15px'};
  color: #707070;
  line-height: 10px;
  margin: 20px 0 5px 0;
`
export const Text = styled.p`
  font-family: ${({ bold }) => `Gilmer-${bold ? 'Bold' : 'Light'}`};
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '14px'};
  color: #707070;
  line-height: 15px;
  margin: 0;
`
export const Description = styled.div`
  font-family: Gilmer-Light;
  font-size: 14px;
  color: #707070;
  word-wrap: break-word;
`
export const WAButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 0;
  background-color: #25D366;
  height: 40px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Gilmer-Light;
  font-size: 15px;
  outline: none;
  -webkit-transition: background-color .5s;
  transition: background-color .5s;
  &:hover {
    background-color: #00a881;
  }
`
export const TelButton = styled.a`
  border-radius: 8px;
  border: 1px solid #707070;
  background-color: transparent;
  color: #707070;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  padding: 10px 5px 10px 5px;
  -webkit-transition: background-color 1s, color 1s;
  transition: background-color 1s, color 1s;

  &:hover {
    background-color: #707070;
    color: #ffffff;
  }
`
export const ControlButton = styled.div`
  border-radius: 5px;
  border: 2px solid #e74c3c;
  background-color: transparent;
  color: #e74c3c;
  cursor: pointer;
  font-family: Gilmer-Bold;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  outline: none;
  width: 70%;
  margin-top: 10px;
  padding: 10px 5px 10px 5px;
`
export const ControlDescription = styled.div`
  border: 1px solid #707070;
  background-color: transparent;
  color: #707070;
  font-family: Gilmer-Light;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  padding: 10px 15px 10px 15px;
`
export const SpecialBadge = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  background-color: #FFBB00;
  border-radius: 5px;
  height: 45px;
  width: 55px;
  font-family: Gilmer-Bold;
  font-size: 16px;
  color: #FFFFFF;

  span {
    font-size: 22px;
  }
`
export const PriceCompare = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`
