import styled from 'styled-components'
import InputMasked from 'react-input-mask'

export const RegisterContainer = styled.form`
  display: flex;
  flex-direction: ${({ mobile }) => mobile ? 'column-reverse' : 'column'};
  justify-content: center;
  align-items: center;
  padding: 16px;
  @media(min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
`
export const RegisterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
  &:first-child {
    border-bottom: 1px solid #EEEEEE;
    margin-bottom: 16px;
  }
  @media(min-width: 768px) {
    border-bottom: 0;
    border-left: 1px solid #EEEEEE;
    &:first-child {
      border-bottom: 0;
      border-left: 0;
    }
  }
`
export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ gmail, primary }) => gmail ? '#ff4646': primary ? '#00bf91' : '#3c4c84'};
  height: 40px;
  width: 300px;
  border-radius: 10px;
  border: 0; 
  color: #FFFFFF;
  margin-bottom: 16px;
  font-family: Gilmer-Light;
  font-size: 16px;
  cursor: pointer;
  outline: none;

  &:active {
    background-color: ${({ gmail }) => gmail ? '#b73535' : '#252f51'};
  }
`
export const InputMask = styled(InputMasked)`
  height: ${({height}) => height ? `${height}px` : '26px'}
  width: ${({width, textAlign}) => width ? `${width}px` : textAlign === 'center' ? '100%' : 'calc(100% - 10px)'};
  border-radius: 10px;
  border: 1px solid #707070; 
  color: #707070;
  font-family: Gilmer;
  font-size: ${({fontSize}) => fontSize ? `${fontSize}px` : '22px'};
  text-align: ${({textAlign}) => textAlign || 'left'};
  outline: none;
  background-color: #FFFFFF;
  padding: ${({textAlign}) => textAlign === 'center' ? '15px 0 15px 0' : '15px 0 15px 10px'};

  &:focus {
    border: 1px solid #00BF91;
  }

  &::placeholder {
    font-family: Gilmer-Light;
  }
`
export const Heading = styled.h1`
  color: #707070;
  font-weight: bold;
  font-size: 22px;
`
export const Error = styled.p`
  color: #b73535;
  font-size: 14px;
`