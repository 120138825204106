import React from 'react'
import { Info, PrimaryText, SecondaryText, Dropdown, InfoContainer } from './styles.js'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Arrow from '@components/Icons/Arrow'
import './index.css'

class Container extends React.Component {
  constructor() {
    super()
    this.state = {
      visible: false
    }
  }

  toggleDropwdown = () => {
    this.setState(state => ({ visible: !state.visible }))
  }
  
  closeDropdown = () => {
    this.setState(state => ({ visible: false }))
  }

  render() {
    const { icon, primaryText, secondaryText, dropdown = false, children } = this.props
    const { visible } = this.state
    return (
      <InfoContainer>
        <Info onClick={this.toggleDropwdown} onBlur={this.closeDropdown} tabIndex={0} dropdown={dropdown}>
          <Grid>
            <Row middle="xs">
              <Col xs={3}>
                <Row>{icon}</Row>
              </Col>
              <Col xs={dropdown ? 7 : 8}>
                <Row>
                  <PrimaryText>{primaryText}</PrimaryText>
                </Row>
                <Row>
                  <SecondaryText>{secondaryText}</SecondaryText>
                </Row>
              </Col>
              {dropdown && 
                <Col xs={2}>
                  <Arrow style={{transform: visible ? 'rotate(180deg)' : 'rotate(0)' }}/>
                </Col>
              }
            </Row>
          </Grid>
          </Info>
          {dropdown &&
            <Dropdown visible={visible} >
              {children}
            </Dropdown>}
      </InfoContainer>
    )
  }
}

 export default Container