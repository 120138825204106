import styled from 'styled-components'

export const List = styled.ul`
  list-style-type: none;
`

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 30px;
`

export const IconButton = styled.button`
  border-radius: 5px;
  border: 0;
  background-color: #707070;
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  padding: 7px;
`

export const OutlineButton = styled.button`
  border-radius: 5px;
  border: 1px solid #707070;
  background-color: transparent;
  color: #707070;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  padding: 10px 5px 10px 5px;
  -webkit-transition: background-color 1s, color 1s;
  transition: background-color 1s, color 1s;

  &:hover {
    background-color: #707070;
    color: #ffffff;
  }
`
