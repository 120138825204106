import { all, takeLatest } from 'redux-saga/effects'
import { Types } from '../actions'
import Api from '../services/API'
import SearchApi from '../services/SearchAPI'

// Sagas
import { startup } from './StartupSagas'
import { getProducts, getProduct } from './ProductSagas'
import { login, refreshToken, register } from './LoginSagas'
import { getCategories } from './CategorySagas'
import { getDeliverySchedule } from './DeliverySagas'
import { getBanners } from './BannerSagas'
import { getFeaturedProducts } from './FeaturedProductSagas'
import { getAboutUs } from './AboutUsSagas'
import { searchProducts } from './SearchSagas'

export default function * rootSaga() {
  yield all([
    takeLatest(Types.STARTUP_REQUEST, startup, Api),
    takeLatest(Types.PRODUCT_LIST_REQUEST, getProducts, SearchApi),
    takeLatest(Types.PRODUCT_DETAIL_REQUEST, getProduct, Api),
    takeLatest(Types.LOGIN_REQUEST, login, Api),
    takeLatest(Types.REFRESH_TOKEN_REQUEST, refreshToken, Api),
    takeLatest(Types.REGISTER_REQUEST, register, Api),
    takeLatest(Types.CATEGORY_LIST_REQUEST, getCategories, Api),
    takeLatest(Types.DELIVERY_SCHEDULE_REQUEST, getDeliverySchedule, Api),
    takeLatest(Types.BANNER_LIST_REQUEST, getBanners, Api),
    takeLatest(Types.FEATURED_PRODUCT_LIST_REQUEST, getFeaturedProducts, Api),
    takeLatest(Types.ABOUT_US_REQUEST, getAboutUs, Api),
    takeLatest(Types.PRODUCT_SEARCH_REQUEST, searchProducts, SearchApi),
  ])
}
