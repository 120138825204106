import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const ProductCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 2px 4px 10px rgba(0,0,0, 0.2);
  height: 160px;
  padding: 12px;
`
export const ProductContent = styled(Link)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70%;
  cursor: pointer;
`
export const ProductFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 30%;
`
export const Text = styled.h2`
  font-family: ${({ bold }) => `Gilmer-${bold ? 'Bold' : 'Light'}`};
  font-size: 13px;
  color: #707070;
  line-height: 12px;
`
export const Image = styled(LazyLoadImage)`
  max-height: 90px;
  z-index: 1;
`
export const DetailsButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  border-radius: 3px;
  border: 0;
  background-color: #00BF91;
  height: 30px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Gilmer-Bold;
  font-size: 15px;
  outline: none;
  padding: 0px 10px 0px 10px;
  margin: 0 0 0 auto;

  -webkit-transition: background-color .5s;
  transition: background-color .5s;
  &:hover {
    background-color: #00a881;
  }
`
export const SpecialBadge = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFBB00;
  border-radius: 5px;
  height: 40px;
  width: 50px;
  right: 10px;
  top: -10px;
  font-family: Gilmer-Bold;
  font-size: 13px;
  box-shadow: 2px 4px 10px rgba(0,0,0, 0.2);
  color: #FFFFFF;
  z-index: 999;

  span {
    font-size: 18px;
  }
`
export const PriceCompare = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 10px;

  h2 {
    line-height: 16px !important;
    margin: 0px;
  }
`
