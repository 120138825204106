const ERROR_CODES = {
  'email_password_invalid': 'Email/senha incorreto',
  'email_already_exist': 'Email já em uso',
  'network_error': 'Não foi possível contactar o servidor',
  'connection_error': 'Sem conexão à internet',
  'invalid_creditcard': 'Cartão de crédito inválido!',
  'payment_option_not_found': 'Método de pagamento não disponível, por favor entre em contato conosco.'
}

export default (code = '') => {
  return ERROR_CODES[code.toLowerCase()] || code.toLowerCase()
}
