import styled from 'styled-components'

export const FooterContainer = styled.footer`
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  color: #A8A8A8;
  font-size: 10px;
  padding: 16px 0;
  z-index: 800 !important;
`
export const FooterRow = styled.div`
  display: flex;
  width: 89%;
  justify-content: center;
  ${({ borderBottom }) => borderBottom ? 'border-bottom: 1px solid #707070;' : ''}
`
export const FooterCol = styled.div`
  width: ${({ width }) => width};
  align-self: flex-end;
  padding: 8px;
`
export const Img = styled.img`
  max-width: 100%;
  height: ${({ height }) => height};
`