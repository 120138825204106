import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PrimaryButton, OutlineButton } from './styles'

export default class Button extends Component {
  static propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary']),
    onClick: PropTypes.func,
    style: PropTypes.any
  }

  static defaultProps = {
    color: 'primary',
    style: {}
  }

  render() {
    const { color, onClick, children, style } = this.props

    let buttonComponent = <PrimaryButton style={style} onClick={onClick}>{children}</PrimaryButton>
    if (color === 'secondary') {
      buttonComponent = <OutlineButton style={style} onClick={onClick}>{children}</OutlineButton>
    }

    return buttonComponent
  }
}
