import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  products: []
})

export const cartSetProductRequest = (state = INITIAL_STATE, { products }) =>
  Object.assign({}, state, {
    products
  })

export const cartListProductRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state)

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.CART_SET_PRODUCT_REQUEST]: cartSetProductRequest,
  [Types.CART_LIST_PRODUCT_REQUEST]: cartListProductRequest
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
