import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: false,
  schedule: null
})

export const deliveryScheduleRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    schedule: null
  })

export const deliveryScheduleSuccess = (state = INITIAL_STATE, { schedule }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    schedule
  })

export const deliveryScheduleFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    schedule: null,
    error
  })

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.DELIVERY_SCHEDULE_REQUEST]: deliveryScheduleRequest,
  [Types.DELIVERY_SCHEDULE_SUCCESS]: deliveryScheduleSuccess,
  [Types.DELIVERY_SCHEDULE_FAILURE]: deliveryScheduleFailure
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
