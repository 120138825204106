import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Arrow from '@components/Icons/Arrow'
import { DropdownContainer, DropdownItemContainer, Dropdown, DropdownItem, Label } from './styles'
import './index.css'

export default class FilterDropdown extends Component {
  static propTypes = {
    open: PropTypes.bool,
    value: PropTypes.string,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    onSelect: PropTypes.func
  }

  static defaultProps = {
    open: false,
    value: '',
    options: [],
    placeholder: 'Categoria',
    onSelect: () => {}
  }

  constructor(props) {
    super(props)

    this.state = {
      open: false,
      selectedValue: ''
    }
  }

  componentDidMount() {
    const { open, value } = this.props

    this.setState({
      open,
      selectedValue: value
    })
  }

  componentWillReceiveProps(newProps) {
    const { selected } = newProps

    if (selected !== undefined) {
      this.setState({
        selectedValue: selected
      })
    }
  }

  toggle = () => {
    this.setState(state => ({
      ...state,
      open: !state.open
    }))
  }

  onSelect = (option) => {
    const { onSelect } = this.props

    this.setState({
      selectedValue: option.value,
      open: false
    }, () => {

      onSelect(option)
    })
  }

  _renderDropdown = () => {
    if (this.container) {
      const { options } = this.props
      const { open } = this.state
      
      let width = this.container.offsetWidth
      
      // Fixes width by getting the highest name length
      const sizes = options.map(p => p.name.length).sort((a, b) => b - a)
      if (sizes.length > 0) {
        width += sizes[0] * 2
      }

      return (
        <Dropdown className="filter-dropdown" width={width + 40} ref={(refs) => this.dropdown = refs} open={open}>
          {
            options.map((option, index) => {
              const { name, link, metaTitle, metaDescription, isSubcategory } = option

              let historyState = {}
              if (metaTitle && metaDescription) {
                if (isSubcategory) {
                  historyState = {
                    metas: true,
                    subcategory: {
                      meta_title: metaTitle,
                      meta_description: metaDescription
                    }
                  }
                } else {
                  historyState = {
                    metas: true,
                    category: {
                      meta_title: metaTitle,
                      meta_description: metaDescription
                    }
                  }
                }
              }

              if (link) {
                return (
                  <Link key={index} to={{ pathname: link, state: historyState }} onClick={() => this.onSelect(option)}>
                    <DropdownItem>{name}</DropdownItem>
                  </Link>
                )
              }

              return <DropdownItem key={index} onClick={() => this.onSelect(option)}>{name}</DropdownItem>
            })
          }
        </Dropdown>
      )
    }
  }

  render() {
    const { placeholder, options } = this.props
    const { selectedValue, open } = this.state

    const option = options.find(p => (typeof p.value === 'number' ? (p.value === parseInt(selectedValue)) : (p.value === selectedValue)))

    return (
      <DropdownContainer>
        <DropdownItemContainer ref={(refs) => this.container = refs} onClick={this.toggle}>
          <Label>{option ? option.name : placeholder}</Label>
          <Arrow className={open || option ? 'filter-dropdown-open' : 'filter-dropdown-close'} />
        </DropdownItemContainer>
        {this._renderDropdown()}
      </DropdownContainer>
    )
  }
}